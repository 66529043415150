.luxury__cover__page {
    background-image: url("../../../../public/luxury-apartments.png");
    background-size: cover;
    background-position: top;
    height: 100vh;
    justify-content: center;
    padding-left: 3%;
    padding-right: 3%;
    padding-bottom: 3%;
}

.luxury_cover_page_title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
.luxury_cover_page_title_german {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  

.luxury_cover_page_title h1 {
    font-size: 10vw;
    color: white;
    font-family: "Spectral SC";
}
